import { FormSelectProps } from '@agro-club/agroclub-shared'
import FormSelectShared from '../../FormSelectShared'
import { WrapperFormControl } from '../styled'
import { useTranslation } from 'react-i18next'
import { FC } from 'react'
import { isUS } from 'env'
import { AddressTitle } from 'views/components/AddressTitle/AddressTitle'

export const AddressCommon: FC<FormSelectProps> = ({ ...props }) => {
  const { t } = useTranslation()

  const getLabel = (dto) =>
    isUS ? (
      <div title={`${dto.title}, ${dto.address}`}>
        <AddressTitle as="span" accent>
          {dto.title}
        </AddressTitle>
        {', '}
        {dto.address}
      </div>
    ) : (
      dto.address
    )

  return (
    <WrapperFormControl>
      <FormSelectShared
        placeholder={t('bid:form.userAddressPlaceholder')}
        getLabel={getLabel}
        showSearch={false}
        simpleApiFormat
        {...props}
      />
    </WrapperFormControl>
  )
}
