import { BidContractFilter } from './domain/bid/api'
import { BidStatus, BidTypes } from './domain/bid/types'

const addTrailingSlash = (url: string) => (url.endsWith('/') ? url : `${url}/`)

const urlMaker = (url: string) => (id?: number | string) => {
  if (typeof id === 'undefined') {
    return addTrailingSlash(url)
  }
  return `${addTrailingSlash(url)}${id}/`
}

const LogisticsKR = {
  requests: urlMaker('/new-logistics/requests/'),
  requestStatus: (requestId: string) => `/new-logistics/requests/${requestId}/change_status/`,
  tripsForDeal: (requestId: string) => `/new-logistics/trips/?request=${requestId}`,
  trips: urlMaker('/new-logistics/trips/'),
  getRequestTtnList: (requestId: string) => `/new-logistics/requests/${requestId}/trip-ttn-documents/`,
  downloadAllTtn: (requestId: string) => `/new-logistics/requests/${requestId}/download-ttn-documents/`,
  tripsWithoutTtn: () => '/new-logistics/trips/ttn-not-uploaded/',
  draftTrips: (bidId?: string) => (bidId ? `new-logistics/trips/?status=on_approval&request=${bidId}` : ''),
  approvedTrips: (bidId?: string) => (bidId ? `new-logistics/trips/?status=approved&request=${bidId}` : ''),
  tripDocuments: (tripId: string, documentType?: 'ttn_1' | 'ttn_2') =>
    `/new-logistics/trips/${tripId}/documents/${documentType ? `?document_type=${documentType}` : ''}`,
  tripsDocument: (tripId: string, docId: string) => `/new-logistics/trips/${tripId}/documents/${docId}`,
  products: urlMaker('/new-logistics/products/'),
  loadingWarehouse: urlMaker('/new-logistics/warehouses/loading/'),
  unloadingWarehouse: urlMaker('/new-logistics/warehouses/unloading/'),
  trailersByCarrier: (carrierId) => `/new-logistics/trailers/?carrier=${carrierId}`,
  activeTrailers: () => `/new-logistics/trailers/?status=active`,
  trailers: urlMaker(`/new-logistics/trailers/`),
  trailerChangeStatus: (id) => `/new-logistics/trailers/${id}/change_status/`,
  driversByCarrier: (carrierId: string, carId?: string) =>
    `/new-logistics/drivers/?carrier=${carrierId}${carId ? `&car=${carId}` : ''}`,
  drivers: urlMaker('/new-logistics/drivers/'),
  driverDocuments: (driverId: number) => `/new-logistics/drivers/${driverId}/documents/`,
  driverDocument: (driverId: number, documentId: string) =>
    `/new-logistics/drivers/${driverId}/documents/${documentId}/`,
  driverDocumentTypes: (driverId: number) => `/new-logistics/drivers/${driverId}/document_types/`,
  car: urlMaker('/new-logistics/cars/'),
  carStatus: (carId) => `new-logistics/cars/${carId}/change_status/`,
  carFiles: (carId: string, fileId?: string) => `/logistics/cars/${carId}/files/${fileId ? `${fileId}/` : ''}`,
  carrierTrailers: (carrierId: string) => `/logistics/carriers/${carrierId}/trailers/`,
  carriers: urlMaker('/new-logistics/carriers/'),
  carrierComments: (carrierId: string) => `/new-logistics/carriers/${carrierId}/comments/`,
  findCarrierByInn: (inn: string) => `/new-logistics/carriers/?search=${inn}`,
  carrierCars: (carrierId: string) => `/new-logistics/cars/?carrier=${carrierId}`,
  carrierTrailersV2: (carrierId: string, carId?: string) =>
    `/new-logistics/trailers/?carrier=${carrierId}${carId ? `&car=${carId}` : ''}`,
  carrierStatus: (carrierId: string) => `/new-logistics/carriers/${carrierId}/change_status/`,
  carrierDocuments: (carrierId: string) => `/new-logistics/carriers/${carrierId}/documents/`,
  carrierDocument: (carrierId: string, documentId: string) =>
    `/new-logistics/carriers/${carrierId}/documents/${documentId}/`,
  carrierDocumentsTypes: (carrierId: string) => `/new-logistics/carriers/${carrierId}/document_types/`,
  carrierFiles: (carrierId: string) => `/new-logistics/carriers/${carrierId}/files/`,
  carrierFile: (carrierId: string, fileId: string) => `/new-logistics/carriers/${carrierId}/files/${fileId}/`,
  carrierEmployees: (carrierId: string) => `/new-logistics/carriers/${carrierId}/employees/`,

  customers: urlMaker('/new-logistics/customers/'),
  requestsWithStatus: (status: string) => `/new-logistics/requests/?logistics_status=${status}`,
  involvedCars: (requestId: string) => `/new-logistics/requests/${requestId}/involved-cars/`,
  relevantCars: (requestId?: string) => (requestId ? `/new-logistics/requests/${requestId}/relevant-cars/` : ''),
  activeBidTripOffers: (bidId?: string) => `/new-logistics/trip-offers/?request=${bidId}`,
  tripOffers: () => '/new-logistics/trip-offers/',
  tripOfferClose: (tripOfferId: string) => `/new-logistics/trip-offers/${tripOfferId}/close/`,
  ratesWithRegion: () => '/logistics/rates/rates-with-region/',
  ratesWithoutRegion: () => '/logistics/rates/rates-without-region/',
  ratesWithoutRegionAndProductType: urlMaker('/logistics/rates/rates-without-region-and-product-type/'),
  relatedRates: () => '/logistics/rates/related-logistics-rates/',
  averageRates: () => '/logistics/rates/rates-without-region-and-product-type/default-logistics-rates/',
  addCompanyEmployee: (companyId: string) => `/new-logistics/carriers/${companyId}/employees/`,
}

export const endpoints = {
  login: () => '/auth/login/',
  getCode: () => '/auth/get_code/',
  refreshToken: () => '/auth/token-refresh/',

  users: urlMaker('/users/'),
  coordinatorsShort: urlMaker('/users/coordinators/'),
  brokers: urlMaker('/users/brokers/'),
  userFiles: (userId: string, fileId?: string) => `/users/${userId}/files/${fileId ? `${fileId}/` : ''}`,
  carriersDocuments: (id: string) => `/logistics/carriers/${id}/documents/`,
  profile: () => '/users/me/',
  myTasks: () => '/users/me/tasks/',

  getUserById: (userId: string) => `/users/${userId}/`,
  userBidList: (userId: string) => `/users/${userId}/bids/`,
  userOpenBids: (userId: string) => `/users/${userId}/bids/?status=published`,
  userBidsForDeal: (userId: string) => `/users/${userId}/bids-for-deal/`,
  userBidListWithPagination: (userId: string, topBid?: string) =>
    `/users/${userId}/bids/with-pagination/${topBid ? `?top_bid=${topBid}` : ''}`,
  userBids: (userId: string, statusBid: string) => `/users/${userId}/bids/?status=${statusBid}`,
  userComments: (userId: string) => `/users/${userId}/comments/`,
  userAddress: (userId: string, addressId?: string) => `/users/${userId}/addresses/${addressId ? `${addressId}/` : ''}`,
  userLegalTypes: (userId: string) => `/users/${userId}/legal-types/`,
  blockUser: (userId: string) => `/users/${userId}/block/`,
  blockedReasons: () => '/users/blocked-reasons/',
  userTasks: (userId: string) => `/users/${userId}/tasks/`,
  userContacts: (userId: string, contactId?: string) => `/users/${userId}/contacts/${contactId ? `${contactId}/` : ''}`,
  userCars: (userId: string) => `/users/${userId}/cars/`,
  userVerifyCoordinator: (userId: string) => `/users/${userId}/verify-as-coordinator/`,
  userVerifySecurity: (userId: string) => `/users/${userId}/verify-as-security/`,
  userVerifyKyc: (userId: string) => `/users/${userId}/verify-as-kyc/`,
  keyUser: (userId: string) => `/users/${userId}/mark-significant/`,
  activeMarketUsers: (profileType: string) => `/users/?is_active=true&profile_type=${profileType}`,
  specificationForPurchase: (ownerId: string, productSlug: string) =>
    `/users/${ownerId}/specifications/?status=open&status=closed&product=${productSlug}`,

  userDocumentTypes: (id: string) => `/users/${id}/document_types/`,
  userDocumentFiles: (id: string) => `/users/${id}/documents/`,
  userDeleteDocumentFile: (id: string, document_id: string) => `/users/${id}/documents/${document_id}/`,
  userDocuments: (id: string) => `/users/${id}/documents/`,
  userSpecifications: (id: string) => `/users/${id}/specifications/`,
  userSpecification: (userId: string, itemId: string) => `/users/${userId}/specifications/${itemId}/`,

  userFilesManagement: (userId: string, specificationId: string) =>
    `/users/${userId}/specifications/${specificationId}/files/`,

  userCoordinatorTypes: urlMaker('/users/coordinator-types/'),
  newUserTasks: (userId: string) => `/users/${userId}/user-tasks/`, //TODO rename after remove old endpoint
  userRatingByType: (userId: string) => `users/${userId}/ratings-by-type/`,
  userRatingByDeal: (userId: string) => `users/${userId}/ratings-by-deal/`,
  userProductTags: (userId: string, id?: string) => `users/${userId}/product-tags/${id ? `${id}/` : ''}`,
  userProductsForNotifications: (userId: string) => `/users/${userId}/sms-notification-settings/`,
  marketingProductSms: (userId: string) => `/users/${userId}/marketing-product-sms/`,
  productsChoicesForSms: () => `/products/choices/sms-notifications/`,

  userSetTrader: (userId: string) => `users/${userId}/set-trader/`,
  userFeature: () => `/users/user-feature/`,

  carDrivers: (carId: string, driverId?: string) =>
    `/logistics/cars/${carId}/drivers/${driverId ? `${driverId}/` : ''}`,
  allDrivers: (driverId: string) => `/logistics/drivers/${driverId}/`,
  carTrips: (carId: string) => `/logistics/cars/${carId}/trips/`,
  carrierDocumentTypes: (id: string) => `logistics/carriers/${id}/document_types/`,
  carrierDocumentFiles: (id: string) => `logistics/carriers/${id}/documents/`,
  carrierDeleteDocumentFile: (id: string, document_id: string) => `/logistics/carriers/${id}/documents/${document_id}/`,
  carChangeStatus: (carId: string) => `/logistics/cars/${carId}/change_status/`,

  company: urlMaker('/companies/'),
  employee: (companyId: string) => `/companies/${companyId}/employees/`,
  activeEmployees: (companyId: string) => `/companies/${companyId}/employees/?is_active=true`,
  companyDadata: () => '/companies/suggest/',
  companyExtraData: (companyId: string, id?: string) => `/companies/${companyId}/extra-data/${id ? `${id}/` : ''}`,
  legalTypes: () => '/companies/legal-types/',
  companyContracts: (id: string) => `/companies/${id}/contracts/`,
  contractFiles: (companyId: string, contractId: string) => `/companies/${companyId}/contracts/${contractId}/files/`,
  deleteContractFile: (companyId: string, contractId: string, fileId: string) =>
    `/companies/${companyId}/contracts/${contractId}/files/${fileId}/`,
  companyActs: (companyId: string) => `companies/${companyId}/reconciliation-acts/`,
  companyAct1C: (companyId: string) => `companies/${companyId}/reconciliation-acts/request-upload/`,
  removeAct: (companyId: string, fileId: string) => `companies/${companyId}/reconciliation-acts/${fileId}/`,
  removeAllActs: (companyId: string) => `companies/${companyId}/reconciliation-acts/delete-all-acts/`,
  requestFactoring: (companyId: string) => `/companies/${companyId}/factoring/`,
  changeFactoringStatus: (companyId: string, factoringId?: string) =>
    `/companies/${companyId}/factoring/${factoringId}/change_status/`,

  bid: urlMaker('/bids/'),
  bidFiles: (id: string) => `/bids/${id}/files/`,
  deleteBidFiles: (bidId: string, fileId: string) => `/bids/${bidId}/files/${fileId}/`,
  bidHistory: (id: string) => `/bids/${id}/history/`,
  removeBidFile: (bidId: string, fileId: string) => `/bids/${bidId}/files/${fileId}/`,
  bidPurchase: () => '/bids/?bid_type=purchase',
  bidSale: () => '/bids/?bid_type=sale',
  bidChangeStatus: (id: string) => `/bids/${id}/change_status/`,
  deals: (bidId: string) => `/bids/${bidId}/deals/`,
  matchesForBids: (bidId: string) => `/bids/${bidId}/matches/`,
  closedMatchesForBids: (bidId: string) => `/bids/${bidId}/matches/closed/`,
  completedMatchesForBids: (bidId: string) => `/bids/${bidId}/matches/completed/`,
  responsesForBids: (bidId: string) => `/bids/${bidId}/responses/`,
  closedResponsesForBids: (bidId: string) => `/bids/${bidId}/responses/closed/`,
  potentialBid: urlMaker('/products/product-stocks/'),
  userPotentialBidList: (userId: string) => `/users/${userId}/product-stocks/`,

  seasonsList: (productId = '') => `/catalog/seasons/?product=${productId}`,

  products: urlMaker('/products/'),
  productById: (id: string) => `/products/${id}/`,
  productChoices: () => '/products/choices/',
  productChoicesForSmsNotifications: () => '/products/choices/sms-notifications/',

  teams: () => '/teams/',

  deal: urlMaker('/deals/'),
  dealDocumentRequest: urlMaker('/deals/documents-requests/'),
  dealWithoutDocumentRequest: (id: string) => `/deals/${id}/no-documents-request/`,
  dealDocumentRequestComments: (id: string) => `/deals/documents-requests/${id}/comments/`,

  deleteWithoutDocumentsRequest: (id: string) => `/deals/${id}/cancel-no-documents-request/`,
  documentsRequest: (id: string) => `/deals/documents-requests/${id}/`,

  dealDocumentRequestStatuses: () => `/deals/documents-requests/statuses/`,
  dealDocumentChangeStatuses: (id: string) => `/deals/documents-requests/${id}/change_status/`,
  bidCloning: (dealId: string) => `/deals/${dealId}/clone-deal/`,
  dealActual: urlMaker('/deals/actual/'),
  dealClosed: urlMaker('/deals/closed/'),

  dealActualWithStatus: (status: string) => `/deals/actual/?status=${status}`,
  dealClosedWithStatus: (status: string) => `/deals/closed/?status=${status}`,

  dealStatuses: () => '/deals/statuses/',
  dealShipment: (dealId: string, shipmentId?: string) =>
    `/deals/${dealId}/shipments/${shipmentId ? `${shipmentId}/` : ''}`,
  dealComment: (dealId: string) => `/deals/${dealId}/comments/`,
  dealStatus: (dealId: string) => `/deals/${dealId}/change_status/`,
  dealClose: (dealId: string) => `/deals/${dealId}/close/`,
  dealUpdatePurchaseBid: (dealId: string) => `/deals/${dealId}/update_purchase_bid/`,
  dealUpdateSaleBid: (dealId: string) => `/deals/${dealId}/update_sale_bid/`,
  dealFiles: (dealId: string, fileId?: string) => `/deals/${dealId}/files/${fileId ? `${fileId}/` : ''}`,
  dealFileList: (dealId: string) => `/deals/${dealId}/files/`,
  sellerSpecification: (dealId: string, specId?: string) =>
    `/deals/${dealId}/sale-specification/${specId ? `${specId}/` : ''}`,
  dealsCloseReasons: () => '/deals/closed_reasons/',
  sellerSpecificationFiles: (dealId: string, specId: string, fileId?: string) =>
    `/deals/${dealId}/sale-specification/${specId}/files/${fileId ? `${fileId}/` : ''}`,
  dealTypes: () => '/deals/types/',
  dealMargins: () => 'deals/margins/',
  dealGmvs: () => 'deals/gmvs/',
  specificationDealBinding: (dealId: string) => `/deals/${dealId}/attach-purchase-specification/`,
  dealChangeApprovalStatus: (dealId: string) => `/deals/${dealId}/change_approval_status/`,
  dealDeclaration: (dealId: string) => `/deals/${dealId}/declaration/`,
  dealDeclarationFiles: (dealId: string) => `/deals/${dealId}/declaration/files/`,
  dealRating: (dealId: string) => `/deals/${dealId}/ratings/`,
  dealTaxCalculation: (dealId: string) => `/taxes/calculation/${dealId}`,
  calcLogisticParams: () => '/deals/logistics-parameters/',
  logisticParamsWithQuery: (purchase_bid_id: string, sale_bid_id: string) =>
    `/deals/logistics-parameters/?purchase_bid_id=${purchase_bid_id}&sale_bid_id=${sale_bid_id}`,
  dealChangeWithoutSpec: (dealId: string) => `/deals/${dealId}/no-purchase-specification/`,
  dealsContracts: urlMaker('deals/sale-contract-requests'),
  dealsContractStatuses: urlMaker('deals/sale-contract-requests/statuses'),
  dealContractChangeStatus: (contractId: string) => `/deals/sale-contract-requests/${contractId}/change_status/`,
  dealContractComments: (contractId: string) => `/deals/sale-contract-requests/${contractId}/comments/`,
  dealShipLoading: (dealId: string) => `/deals/${dealId}/ship-loads/`,
  dealFarmerLoading: (dealId: string) => `/deals/${dealId}/farmer-loads/`,
  dealShipLoadWorkTypes: (dealId: string) => `deals/${dealId}/ship-loads/work-types`,
  dealFarmerLoadWorkTypes: (dealId: string) => `deals/${dealId}/farmer-loads/work-types`,
  dealSingleShipLoading: (dealId: string, shipLoadId: string) => `/deals/${dealId}/ship-loads/${shipLoadId}/`,
  dealSingleFarmerLoading: (dealId: string, farmerLoadId: string) => `/deals/${dealId}/farmer-loads/${farmerLoadId}/`,
  dealHistory: (dealId: string) => `/deals/${dealId}/history/`,

  task: urlMaker('/tasks/'),
  taskDeals: urlMaker('/tasks/deals/'),
  taskAssignees: () => '/tasks/assignees/',
  taskDocsTeamAssignees: () => '/tasks/assignees/?team=9',
  completeTask: (taskId: string) => `/tasks/${taskId}/complete/`,
  removeTask: (taskId: string) => `/tasks/${taskId}/`,
  setTaskToWork: (taskId: string) => `/tasks/${taskId}/to-work/`,
  blackListUser: (userId) => `/users/${userId}/mark-black-listed/`,
  doNotCallReasons: () => '/users/do-not-call-reasons/',
  priorityTask: () => '/tasks/priorities/',
  typesTask: (type) => `/tasks/task-types/?set=${type}`,
  createTask: () => '/tasks/',
  createMyTask: () => '/tasks/my-tasks/',
  tasksTeams: () => '/tasks/teams/',
  allTeams: () => '/teams/?set=all',
  tasksTeamsChoices: () => '/teams/choices',
  schemeTasksTable: () => '/tasks/my-tasks/?schema',
  tasksTableData: () => '/tasks/my-tasks/',
  getTask: (taskId) => `/tasks/${taskId}/`,
  myTasksFiles: (taskId) => `/tasks/my-tasks/${taskId}/files/`,
  removeTasksFile: (taskId, fileId) => `/tasks/my-tasks/${taskId}/files/${fileId}/`,
  tasksStatuses: urlMaker('/tasks/statuses/'),
  getUsersTaskStats: () => `/tasks/user-task-stats/`,
  getCoordinatorTaskStats: (assignee?: string) =>
    `/tasks/coordinator-task-stats/${assignee ? `?assignee=${assignee}` : ''}`,
  distributeTasks: () => `/tasks/distribute/`,
  reassignTasks: () => `/tasks/reassign/`,
  balanceTasks: () => `/tasks/balance/`,
  deassignTask: () => `/tasks/deassign/`,
  taskCallState: (taskId: string) => `/tasks/call-state/${taskId}/`,

  dashboardResponses: () => '/dashboard/responses/',
  dashboardMatches: () => '/dashboard/matches/',
  dashboardNewBids: () => '/dashboard/new-bids/',
  dashboardNewUsers: () => '/dashboard/new-users/',
  dashboardCalls: () => '/dashboard/calls/',
  dashboardOverdueBids: () => '/dashboard/overdue-bids/',

  countries: () => `/geo/countries/`,
  regions: () => '/geo/regions/', // deprecated! use provinces or districts instead of this
  provinces: () => '/geo/provinces/',
  districts: () => '/geo/districts/',

  car: urlMaker('/logistics/cars/'),
  carFiles: (carId: string, fileId?: string) => `/logistics/cars/${carId}/files/${fileId ? `${fileId}/` : ''}`,
  relevantCars: (dealId: string) => `/logistics/deals/${dealId}/relevant-cars/`,
  involvedCars: (dealId: string) => `/logistics/deals/${dealId}/involved-cars/`,

  logisticsDeal: urlMaker('/logistics/deals/'),
  trip: urlMaker('/logistics/trips/'),
  carrier: urlMaker('/logistics/carriers/'),
  userCalls: (userId: string) => `/users/${userId}/calls/`,
  carDeals: (carId: string) => `/logistics/cars/${carId}/deals/`,
  logisticStatuses: '/logistics/deals/statuses/',
  logisticChangeStatus: (id: string) => `/logistics/deals/${id}/change_status/`,

  trailers: urlMaker('/logistics/trailers/'),
  carrierTrailers: (carrierId: string) => `/logistics/carriers/${carrierId}/trailers/`,
  archiveTrailers: (id: string) => `/logistics/trailers/${id}/change_status/`,

  dispatching: (page_size?: number) => `/deals/dispatching/?page_size=${page_size}`,
  dispatchingClosed: () => `/deals/dispatching/closed/`,
  dispatchingItem: (id: string) => `/deals/dispatching/${id}/`,
  dispatchingTotals: () => `/deals/dispatching/totals/`,
  dispatchingStatuses: () => '/deals/dispatching/statuses/',
  dispatchingDates: (currentDate: string) => `/deals/dispatching/dates/?requested-date=${currentDate}`,
  dispatchingLogisticians: () => '/users/logisticians/',

  calls: urlMaker('/vats/calls/'),
  makeCall: urlMaker('/vats/make_call/'),
  accounts: urlMaker('/vats/vats_accounts/'),
  tripComments: (tripId: string) => `/logistics/trips/${tripId}/comments/`,

  tripsDocumentTypes: (id: string) => `logistics/trips/${id}/document_types/`,
  tripsDocumentFiles: (id: string) => `logistics/trips/${id}/documents/`,
  tripsDocuments: (id: string) => `/logistics/trips/${id}/documents/`,
  tripsDeleteDocumentFile: (id: string, document_id: string) => `/logistics/trips/${id}/documents/${document_id}/`,

  callStatistics: urlMaker('/vats/call-statistics/vats-accounts/'),
  callStatisticsAverage: urlMaker('/vats/call-statistics/average/'),
  callStatisticsGeneral: urlMaker('/vats/call-statistics/general/'),

  teamsMembers: urlMaker('/teams/members/'),
  teamsPlans: urlMaker('/teams/plans/'),
  teamsPlansById: (id: string) => `/teams/${id}/plans/`,
  teamsMembersById: (id: string) => `/teams/${id}/with-members/`,
  teamsOverallByMonth: (date: string) => `/teams/plans/overall-by-month/?schedule_year=${date}`,

  files: urlMaker('/files/'),

  specifications: urlMaker('/specifications'),
  specificationComments: (id: string) => `/specifications/${id}/comments/`,
  specificationFiles: (id: string) => `/specifications/${id}/files/`,
  deleteSpecificationFiles: (itemId: string, id: string) => `/specifications/${itemId}/files/${id}/`,
  specificationChangeStatus: (id: string) => `/specifications/${id}/change_status/`,
  specificationDeals: (id: string) => `/specifications/${id}/deals/`,

  dealPlannedCloseDate: (id: string) => `logistics/deals/${id}/set-planned-close-date/`,
  executorsEdit: (id: string) => `/deals/${id}/executors/`,
  executorsByType: (type: string) => `/users/${type}/`,
  eventsCount: urlMaker('/events/count/'),
  getEventChannel: urlMaker('/events/get-channel/'),
  pusherAuthEndpoint: urlMaker('/events/auth/'),
  markEventAsRead: urlMaker('/events/object-events-viewed/'),
  whatsAppTwilio: () => '/twilio/messages/',
  whatsAppDetailTwilio: (coordinator_id: string, phone: string, date: string) =>
    `twilio/messages-details/?coordinator_id=${coordinator_id}&client_phone=${phone}&date_sent=${date}`,
  LogisticsKR,

  samples: urlMaker('/samples/'),
  samplesForUser: (id: string) => `/users/${id}/samples/`,
  samplesForBid: (id: string) => `/bids/${id}/samples/`,
  samplesStatus: () => `/samples/statuses/`,
  sampleComments: (sampleId: string) => `/samples/${sampleId}/comments/`,
  sampleFiles: (sampleId: string, fileId?: string) => `/samples/${sampleId}/files/${fileId ? `${fileId}/` : ''}`,

  contracts: urlMaker('/contracts/'),
  contractComments: (id: string) => `/contracts/${id}/comments/`,
  contractDeals: (id: string) => `contracts/${id}/deals/`,
  contractsWithType: (bid_type: BidTypes) => `/contracts/?bid_type=${bid_type}`,
  closeContract: (idContract: string) => `/contracts/${idContract}/close/`,
  changeStatusContract: (idContract: string) => `/contracts/${idContract}/change_status/`,
  contractClosedReasons: () => `/contracts/closed_reasons/`,
  contractStatuses: () => `/contracts/statuses/`,
  bidContractFiles: (userId: string, fileId?: string) => `/contracts/${userId}/files/${fileId ? `${fileId}/` : ''}`,
  contractDocumentTypes: (contractId: string) => `/contracts/${contractId}/document_types/`,
  contractDocuments: (contractId: string, dealId?: string) =>
    `/contracts/${contractId}/documents/${dealId ? `?deal_id=${dealId}` : ''}`,
  contractDeleteDocument: (contractId: string, documentId: string) =>
    `/contracts/${contractId}/documents/${documentId}/`,
  contractPaymentTypes: () => `/contracts/payment-types/`,

  contractsTotals: (
    {
      bid_type = BidTypes.sale,
      matchable = false,
      match_bid_id = '',
      status = BidStatus.published,
    } = {} as BidContractFilter,
  ) => `/contracts/totals/?bid_type=${bid_type}&matchable=${matchable}&match_bid_id=${match_bid_id}&status=${status}`,

  dealsManual: () => `/deals/manual/`,
  dealsManualPreview: ({ sale_bid_id = '', purchase_bid_id = '' }) =>
    `/deals/manual/preview/?sale_bid_id=${sale_bid_id}&purchase_bid_id=${purchase_bid_id}`,
  dealsPartyChange: (dealId: string) => `/deals/${dealId}/party-change/`,
  priceCalculator: () => `/taxes/grain_price_calculator/`,
  brRoutesList: () => '/logistics/reference-routes/',
  brRoutesSaleCities: () => '/logistics/reference-routes/regions?sale_cities=true',
  brRoutesSaleRegions: () => '/logistics/reference-routes/regions?sale_regions=true',
  brRoutesPurchaseCities: () => '/logistics/reference-routes/regions?purchase_cities=true',
  brRoutesUploadFile: () => '/logistics/reference-routes/upload/',
}
