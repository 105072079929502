import styled from 'styled-components'
import { Icons, Progress, Spinner, Text } from '@agro-club/agroclub-shared'
import { FC } from 'react'
import { AButton } from 'views/components/Analytics'
import { refetchFunc } from 'modules/domain/common/hooks'
import { endpoints } from 'modules/endpoints'
import { useProgress } from 'hooks/useProgress'
import { apiClient } from 'modules/utils/httpClient'
import { TripDocument } from 'views/pages/LogisticsKR/types'

const ItemWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  column-gap: 4px;
  padding: 5px 4px;
  align-items: center;

  &:hover {
    background-color: ${({ theme }) => theme.color.primary8a};
    border-radius: 4px;
  }
`

const ItemInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 237px;
  min-height: 38px;
  word-break: break-all;
`

const PopoverContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 4px;
  width: 300px;
`

const SpinnerWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 38px;
  width: 100%;
`

type ItemProps = {
  title: string
  error: string
  tripId: string
  itemId: string
  onSuccess: () => void
}

const Item: FC<ItemProps> = ({ itemId, tripId, onSuccess, title, error }) => {
  const [progress, removeDoc] = useProgress(() => apiClient.delete(endpoints.LogisticsKR.tripsDocument(tripId, itemId)))

  const handleRemove = async (e) => {
    e.stopPropagation()
    await removeDoc()
    await onSuccess()
  }

  return (
    <ItemWrapper>
      <ItemInfo>
        <Text color={error ? 'accent' : 'primary'}>{title}</Text>
        {error && (
          <Text typography="bodySmall" color="accent">
            {error}
          </Text>
        )}
      </ItemInfo>
      <AButton
        id="removeTtnFromTrip"
        intent="white"
        Icon={Icons.IconRemove}
        progress={progress}
        onClick={handleRemove}
      />
    </ItemWrapper>
  )
}

type PopoverContentProps = {
  progress: Progress
  documents: TripDocument[]
  onSuccess: refetchFunc | (() => void)
  tripId: string
}

export const PopoverContent: FC<PopoverContentProps> = ({ documents, progress, onSuccess, tripId }) => {
  return (
    <PopoverContentWrapper>
      {progress === Progress.WORK ? (
        <SpinnerWrapper>
          <Spinner />
        </SpinnerWrapper>
      ) : (
        documents.map((doc) => (
          <Item
            key={doc.id}
            onSuccess={onSuccess}
            tripId={tripId}
            itemId={doc.id}
            title={doc.original_filename}
            error={doc.rejection_reason}
          />
        ))
      )}
    </PopoverContentWrapper>
  )
}
