import { FC, useEffect, useState } from 'react'
import { formatQuantity } from 'modules/utils/numbers/formatQuantity'
import { UploadTtnButton } from '../UploadTtnButton/UploadTtnButton'
import { DASH } from 'modules/constants'
import { AntPopover, Icons, Progress, Text } from '@agro-club/agroclub-shared'
import { CellRenders } from 'views/components/Table/renderers'
import { refetchFunc, useSingleEntity } from 'modules/domain/common/hooks'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { AButton } from 'views/components/Analytics'
import { PopoverContent } from './PopoverContent'
import { TripDocument } from 'views/pages/LogisticsKR/types'
import { endpoints } from 'modules/endpoints'

const ButtonsWrapper = styled.div`
  display: flex;
  gap: 4px;
`

const loadKeys = {
  ttnFilesType: 'has_ttn1_files',
  ttnRejectFilesType: 'has_ttn1_rejection_reason',
  quantityType: 'load_quantity',
  dateType: 'load_date',
  fetchFilter: 'ttn_1',
} as const

const unloadKeys = {
  ttnFilesType: 'has_ttn2_files',
  ttnRejectFilesType: 'has_ttn2_rejection_reason',
  quantityType: 'unload_quantity',
  dateType: 'unload_date',
  fetchFilter: 'ttn_2',
} as const

type Props = {
  trip: any
  loadType: 'load' | 'unload'
  onSuccess: (() => void) | refetchFunc
  externallyDeletedDocId?: string
}

export const LoadUploadCell: FC<Props> = ({ loadType, trip, onSuccess, externallyDeletedDocId }) => {
  const { t } = useTranslation('logisticsKR')
  const [popoverVisible, setPopoverVisible] = useState(false)
  const [isReadyToFetch, setIsReadyToFetch] = useState(false)
  const keys = loadType === 'load' ? loadKeys : unloadKeys
  const [docsProgress, docs = [], docsRefetch] = useSingleEntity<TripDocument[]>(
    isReadyToFetch ? endpoints.LogisticsKR.tripDocuments(trip.id, keys.fetchFilter) : undefined,
  )
  const docsIsLoaded = docsProgress === Progress.SUCCESS

  const docUploadOnSuccess = async () => {
    await onSuccess()

    if (docsIsLoaded) {
      docsRefetch(true)
    }
  }

  const docsDeleteOnSuccess = async () => {
    await docsRefetch(true)
    onSuccess()
  }

  const popoverOpenChangeHandler = () => {
    setPopoverVisible((prev) => !prev)

    if (!isReadyToFetch) {
      setIsReadyToFetch(true)
    }
  }

  useEffect(() => {
    if (!externallyDeletedDocId) return

    const externallyDeletedDocument = docs.find((doc) => String(doc.id) === externallyDeletedDocId)

    if (externallyDeletedDocument && docsIsLoaded) {
      docsRefetch()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [externallyDeletedDocId])

  return (
    <div>
      {CellRenders.date(trip[keys.dateType])}
      <Text style={{ marginBottom: '4px' }} typography="accentMedium">
        {trip[keys.quantityType] ? formatQuantity(trip[keys.quantityType]) : DASH}
      </Text>
      <ButtonsWrapper>
        <UploadTtnButton loadType={loadType} tripId={trip.id} onSuccess={docUploadOnSuccess} />
        {trip[keys.ttnFilesType] && (
          <AntPopover
            content={
              <PopoverContent
                tripId={trip.id}
                progress={docsProgress}
                documents={docs}
                onSuccess={docsDeleteOnSuccess}
              />
            }
            placement="bottomRight"
            trigger="click"
            open={popoverVisible}
            onOpenChange={popoverOpenChangeHandler}
          >
            <AButton id="popoverTtnList" intent="secondary" size="small" Icon={Icons.IconAttach} />
          </AntPopover>
        )}
      </ButtonsWrapper>
      {trip[keys.ttnFilesType] && !trip[keys.ttnRejectFilesType] && (
        <Text typography="bodySmall" color="secondary">
          {t('details.file_uploaded')}
        </Text>
      )}
      {trip[keys.ttnRejectFilesType] && (
        <Text typography="bodySmall" color="accent">
          {t('common:hasError')}
        </Text>
      )}
    </div>
  )
}
