import { AButton } from 'views/components/Analytics'
import { Icons, Progress, Spinner } from '@agro-club/agroclub-shared'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { FileTtnItem } from 'views/pages/LogisticsKR/LogisticRequests/RequestDetails/components/FilesTtn/FileTtnItem'
import { useParams } from 'react-router-dom'
import { endpoints } from 'modules/endpoints'
import { FC } from 'react'
import { ClientPaginationSmall } from 'views/components/ClientSmallPagination/ClientPaginationSmall'
import { apiClient } from 'modules/utils/httpClient'
import { useAProgress } from 'hooks/useAProgress'
import { SpinnerWrapper } from 'views/pages/LogisticsKR/components/styled'
import { useTtnFilesContext } from '../../TtnFilesContext'

const SpinnerWrapperStyled = styled(SpinnerWrapper)`
  height: 335px;
`
const MarginWrapper = styled.div`
  margin-top: 12px;
`

type Props = {
  refetchTripsTable: () => void
}

export const FilesTtn: FC<Props> = ({ refetchTripsTable }) => {
  const { id } = useParams()
  const { t } = useTranslation('logisticsKR')
  const { ttnListProgress, ttnList, ttnListRefetch, ttnListTotal, ttnListParams, setTtnListParams, setDeletedFileId } =
    useTtnFilesContext()

  const [downloadProgress, downloadHandler] = useAProgress(
    async () =>
      await apiClient.get(
        endpoints.LogisticsKR.downloadAllTtn(id),
        {},
        {
          responseType: 'blob',
        },
      ),
    { eventName: 'downloadAllTtnFiles' },
  )

  const handleChangePage = (page: number) => setTtnListParams((prev) => ({ ...prev, page }))

  return (
    <>
      {!!ttnListTotal && (
        <AButton
          id="downloadAllTtn"
          onClick={downloadHandler}
          progress={downloadProgress}
          intent="secondary"
          Icon={Icons.IconDownload}
        >
          {t('details.accordion.downloadAllFiles')}
        </AButton>
      )}
      <MarginWrapper>
        {ttnListProgress === Progress.WORK ? (
          <SpinnerWrapperStyled>
            <Spinner />
          </SpinnerWrapperStyled>
        ) : (
          ttnList?.map((file) => (
            <FileTtnItem
              refetchTripsTable={refetchTripsTable}
              refetch={ttnListRefetch}
              file={file}
              key={file.id}
              setDeletedTtnIdForSync={setDeletedFileId}
            />
          ))
        )}
      </MarginWrapper>
      <ClientPaginationSmall total={ttnListTotal} currPage={ttnListParams.page} setCurrPage={handleChangePage} />
    </>
  )
}
