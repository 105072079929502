import ADefaultModalFooter from 'views/components/DefaultModalFooter/ADefaultModalFooter'
import { ASharedModal, SharedModalTitle } from 'views/components/SharedModal'
import { Container } from '../UserInfo/ProductTags/ProductTagsModal'
import FormSelectShared from 'views/components/FormSelectShared'
import { refetchFunc } from 'modules/domain/common/hooks'
import { apiClient } from 'modules/utils/httpClient'
import { useAFormHandler } from 'analytics/hooks'
import { User } from 'modules/domain/user/types'
import { useTranslation } from 'react-i18next'
import { endpoints } from 'modules/endpoints'
import { useFormik } from 'formik'
import { FC } from 'react'

type Props = {
  refetch: refetchFunc
  onClose: () => void
  user: User
}

export const NotificationModal: FC<Props> = ({ user, refetch, onClose }) => {
  const { t } = useTranslation('user')
  const { formProgress, formHandler } = useAFormHandler()

  const productIds = user.sms_notification_settings.price_sms_products?.map((t) => String(t.id)) || []

  const formik = useFormik<{ price_sms_products: string[] }>({
    initialValues: {
      price_sms_products: productIds,
    },

    onSubmit: formHandler(
      async () => {
        const payload = {
          price_sms_enabled: true,
          price_sms_products: formik.values.price_sms_products,
        }
        return await apiClient.put(endpoints.userProductsForNotifications(user.id), payload)
      },
      {
        onSuccess: async () => {
          await refetch()
          onClose()
        },
        onError: (error) => {
          const { errors } = error
          formik.setErrors(errors)
        },
      },
    ),
  })

  return (
    <ASharedModal size="small" onClose={onClose} id="ProductTagsModal">
      <SharedModalTitle>{t('productTags.modalHeader')}</SharedModalTitle>
      <Container>
        {t('smsNotification.modalText')}
        <FormSelectShared
          endpoint={endpoints.productChoicesForSmsNotifications()}
          placeholder={t('productTags.placeholder')}
          value={formik.values.price_sms_products}
          getLabel={(dto) => dto.title}
          fieldName="price_sms_products"
          disableRightPadding
          showSearch={false}
          simpleApiFormat
          formik={formik}
          multiple
          hideLabel
        />
      </Container>
      <ADefaultModalFooter
        onConfirm={() => formik.handleSubmit()}
        confirmButtonText={t('common:save')}
        disabled={!formik.dirty}
        progress={formProgress}
        onClose={onClose}
      />
    </ASharedModal>
  )
}
